
<v-app id="alerta">
  <div v-if="!isKiosk">
    <v-navigation-drawer
      v-if="isLoggedIn || !isAuthRequired || isAllowReadonly"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      disable-resize-watcher
      fixed
      app
    >
      <v-toolbar flat>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

        <router-link to="/" class="toolbar-title">
          <img
            v-if="$config.site_logo_url"
            :src="$config.site_logo_url"
            height="48"
          />
          <v-toolbar-title v-else class="logo">alerta</v-toolbar-title>
        </router-link>
      </v-toolbar>

      <v-divider />
      <v-list dense>
        <template v-for="(item, index) in items">
          <v-list-item
            v-if="item.icon && item.show"
            :key="item.text"
            v-has-perms="item.perms"
            :to="item.path"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
                <v-icon v-if="item.appendIcon" small>
                  {{ item.appendIcon }}
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else-if="item.queries && item.queries.length > 0"
            :key="item.text"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            sub-group
            no-action
            color="neutral"
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(q, i) in item.queries"
              :key="i"
              @click="submitSearch(q.query)"
            >
              <v-list-item-title v-text="q.text" />
              <v-list-item-action>
                <v-icon small @click.stop="deleteSearch(q)" v-text="q.icon" />
              </v-list-item-action>
            </v-list-item>
          </v-list-group>

          <v-divider v-else-if="item.divider" :key="index" />
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar
      v-if="selected.length == 0 || $route.name !== 'alerts'"
      flat
      class="mb-1"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <router-link to="/" class="toolbar-title">
        <img
          v-if="$config.site_logo_url"
          :src="$config.site_logo_url"
          height="48"
        />
        <v-toolbar-title v-else class="logo">alerta</v-toolbar-title>
      </router-link>

      <v-spacer />

      <v-text-field
        v-if="$route.name === 'alerts' || $route.name === 'incidents'"
        v-model="query"
        :flat="!hasFocus"
        :label="$t('Search')"
        prepend-inner-icon="mdi-magnify"
        solo
        clearable
        dense
        hide-details
        class="mr-3 hidden-sm-and-down"
        @focus="hasFocus = true"
        @blur="hasFocus = false"
        @change="submitSearch"
        @click:clear="clearSearch"
      >
        <template v-slot:append-outer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="saveSearch">mdi-pin</v-icon>
            </template>
            <span>{{ $t('Save') }}</span>
          </v-tooltip>
        </template>
      </v-text-field>

      <div v-if="$route.name === 'alerts'" v-show="isLoggedIn">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="switch-wrapper">
              <v-switch
                :input-value="isWatch"
                hide-details
                open-delay="3000"
                @change="toggle('isWatch', $event)"
              />
            </div>
          </template>
          <span>{{ $t('Watch') }}</span>
        </v-tooltip>
      </div>

      <v-spacer class="hidden-sm-and-down" />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-show="isLoggedIn || !isAuthRequired || isAllowReadonly"
            v-on="on"
            icon
            @click="toggleFullScreen"
          >
            <v-icon>{{
              isFullscreen() ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('FullScreen') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-show="isLoggedIn || !isAuthRequired || isAllowReadonly"
            v-on="on"
            icon
          >
            <v-icon @click="refresh">mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Refresh') }}</span>
      </v-tooltip>

      <v-menu
        v-show="isLoggedIn"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-bottom="15"
        offset-y
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-avatar size="32px">
              <img
                v-if="avatar && !error"
                :src="avatar"
                @error="error = true"
              />
              <v-icon v-else v-text="navbar.signin.icon" />
            </v-avatar>
          </v-btn>
        </template>

        <profile-me v-if="profile" :profile="profile" @close="menu = false" />
      </v-menu>

      <span class="hidden-xs-only" v-show="!isLoggedIn">
        <v-btn
          v-show="isSignupEnabled"
          rounded
          outlined
          color="primary"
          to="/signup"
        >
          {{ $t('SignUp') }}
        </v-btn>
        <v-btn rounded color="primary" to="/login">
          {{ $t('LogIn') }}
        </v-btn>
      </span>
    </v-toolbar>

    <v-toolbar
      v-if="selected.length > 0 && $route.name === 'alerts'"
      class="mb-1"
    >
      <v-btn icon @click="clearSelected">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="hidden-sm-and-down">
        <v-toolbar-title>Back</v-toolbar-title>
      </span>
      <v-spacer />

      <span class="subheading">
        {{ selected.length }}&nbsp;<span class="hidden-sm-and-down">{{
          $t('selected')
        }}</span>
      </span>

      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon plain @click="toggleWatch()">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Watch') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon plain @click="bulkAckAlert()">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Ack') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon plain @click="bulkShelveAlert()">
            <v-icon>mdi-clock-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Shelve') }}</span>
      </v-tooltip>

      <grouping :selected="selected">
        <template v-slot:activator="{ on: openModal, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="openModal.click"
                v-bind="attrs"
                icon
                plain
              >
                <v-icon>mdi-group</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('AddToIncident') }}</span>
          </v-tooltip>
        </template>
      </grouping>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon plain @click="takeBulkAction('close')">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Close') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            v-has-perms="'admin:alerts'"
            icon
            plain
            @click="bulkDeleteAlert()"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Delete') }}</span>
      </v-tooltip>

      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text icon small plain class="px-1 mx-0">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list subheader>
          <v-subheader>Actions</v-subheader>
          <v-divider />
          <v-list-item
            v-for="(action, i) in actions"
            :key="i"
            @click="takeBulkAction(action)"
          >
            <v-list-item-title>{{ action | splitCaps }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-show="isLoggedIn || !isAuthRequired || isAllowReadonly"
            v-on="on"
            icon
            @click="toggleFullScreen"
          >
            <v-icon>{{
              isFullscreen() ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('FullScreen') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-show="isLoggedIn || !isAuthRequired || isAllowReadonly"
            v-on="on"
            icon
          >
            <v-icon @click="refresh">mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Refresh') }}</span>
      </v-tooltip>

      <v-menu
        v-show="isLoggedIn"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-avatar size="32px">
              <img
                v-if="avatar && !error"
                :src="avatar"
                @error="error = true"
              />
              <v-icon v-else v-text="navbar.signin.icon" />
            </v-avatar>
          </v-btn>
        </template>

        <profile-me v-if="profile" :profile="profile" @close="menu = false" />
      </v-menu>

      <span class="hidden-xs-only">
        <v-btn
          v-show="!isLoggedIn && isSignupEnabled"
          rounded
          outlined
          color="primary"
          disabled
        >
          {{ $t('SignUp') }}
        </v-btn>
        <v-btn v-show="!isLoggedIn" rounded color="primary" disabled>
          {{ $t('LogIn') }}
        </v-btn>
      </span>
    </v-toolbar>
  </div>

  <v-main>
    <banner />
    <router-view />
    <snackbar />
  </v-main>

  <div v-if="!isKiosk">
    <span class="hidden-sm-and-up">
      <v-btn
        v-show="!isLoggedIn && isSignupEnabled"
        block
        rounded
        outlined
        color="primary"
        to="/signup"
        :disabled="selected.length > 0"
      >
        {{ $t('SignUp') }}
      </v-btn>
      <v-btn
        v-show="!isLoggedIn"
        block
        rounded
        color="primary"
        to="/login"
        :disabled="selected.length > 0"
      >
        {{ $t('LogIn') }}
      </v-btn>
    </span>
  </div>
</v-app>
