
<v-alert
  v-model="show"
  :type="banner.type"
  :icon="banner.icon"
  dismissible
  outlined
>
  {{ banner.text }}
</v-alert>
