
<v-snackbar v-model="show" :color="snackbar.type" :timeout="snackbar.timeout">
  {{ snackbar.text | capitalize }}

  <template v-slot:action="{ attrs }">
    <v-btn outlined v-bind="attrs" @click="handleClick">
      {{ snackbar.action }}
    </v-btn>
  </template>
</v-snackbar>
